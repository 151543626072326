import React from 'react'
import Layout from '../components/_App/layout'
import Seo from '../components/_App/seo'
import Navbar from '../components/_App/Navbar'
// import Team from '../components/Common/Team'
// import FunFactsArea from '../components/Common/FunFactsArea'
import Footer from '../components/_App/Footer'
import PageBanner from '../components/Common/PageBanner'
// import FeedbackStyleFour from '../components/Common/FeedbackStyleFour'
// import Partner from '../components/MachineLearning/Partner'

import AboutImg from '../assets/images/about4.png'

const About = () => (
  <Layout>
    <Seo title='About Us' />

    <Navbar />

    <PageBanner pageTitle='About Us' />

    <div className='about-area ptb-80'>
      <div className='container-fluid'>
        <div className='row align-items-center'>
          <div className='col-lg-6 col-md-12'>
            <div className='ml-about-img'>
              <img src={AboutImg} alt='' />
            </div>
          </div>

          <div className='col-lg-6 col-md-12'>
            <div className='ml-about-content'>
              <span className='sub-title'>About Us</span>
              <h2>The GeoML Team</h2>
              <div className='bar'></div>
              <p>
                We are scientists, engineers, and students that are enthusiastic
                in exploring the use of Data Science and various methods of Machine
                Learning in geochemical studies of reactive transport and in the
                related education activities.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className='container'>
        <div className='about-inner-area'>
          <div className='row justify-content-center'>
            <div className='col-lg-4 col-md-6 col-sm-6'>
              <div className='about-text'>
                <h3>Our History</h3>
                <p>Started in 2021, we are just at the beginning.</p>
                <p>If you are affiliated at PSI LES or IGS UB, join the GeoML Team
                  and take part in shaping up our history by your achievements!
                </p>
              </div>
            </div>

            <div className='col-lg-4 col-md-6 col-sm-6'>
              <div className='about-text'>
                <h3>Our Mission</h3>
                <p>
                  Our goal is to make methods and software behind the buzzwords
                  such as ML and AI, supported and provided by GeoML PaaS and SaaS,
                  really useful for geochemistry, supported and provided by GeoML
                  PaaS and SaaS. PaaS (Platform as a Service) provides{' '}
                  <a
                    href='https://jupyterlab.readthedocs.io/en/stable/'
                    target='_blank'
                    rel='noreferrer'
                  >
                    JupyterLab
                  </a>{' '}
                  servers; SaaS (Software as a Service) are (web) apps for
                  solving various user tasks in "no code" style, developed by
                  members of the GeoML Team.
                </p>
              </div>
            </div>

            <div className='col-lg-4 col-md-6 col-sm-6'>
              <div className='about-text'>
                <h3>Who We Are</h3>
                <p>
                  The GeoML Team is forming around scientists working at
                  the{' '}
                  <a
                    href='https://www.psi.ch/en/les'
                    target='_blank'
                    rel='noreferrer'
                  >
                    Laboratory for Waste Management
                  </a>{' '}
                  of the{' '}
                  <a
                    href='https://www.psi.ch/en'
                    target='_blank'
                    rel='noreferrer'
                  >
                    Paul Scherrer Institut
                  </a>{' '}
                  (CH) and at the affiliated groups at the{' '}
                  <a
                    href='https://www.geo.unibe.ch/index_eng.html'
                    target='_blank'
                    rel='noreferrer'
                  >
                    Institute of Geological Sciences
                  </a>{' '}
                  of the{' '}
                  <a
                    href='https://www.unibe.ch/index_eng.html'
                    target='_blank'
                    rel='noreferrer'
                  >
                    University of Bern
                  </a>{' '}
                  (CH).
                </p>

                <p>
                  The GeoML servers are configured and maintained by{' '}
                  <a
                    href='https://congineer.com/'
                    target='_blank'
                    rel='noreferrer'
                  >
                    CONGINEER Ltd.
                  </a>{' '}
                  (CH) - the experts in modern and secure full-stack web development.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    {/* <Team /> */}

    {/* <FeedbackStyleFour /> */}

    {/* <FunFactsArea /> */}

    {/* <div className='pb-80'>
      <Partner />
    </div> */}

    <Footer />
  </Layout>
)

export default About
